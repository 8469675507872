
import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Currency from '../../../store/entities/currency'

@Component
export default class CurrencyForm extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;
  @Prop({type: Boolean, default: false}) public toEdit: boolean;
  currency: Currency = new Currency();

  created() {
  }

  save() {
    (this.$refs.currencyForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await this.$store.dispatch({
          type: this.toEdit  ? 'currency/update' : 'currency/create',
          data: this.currency
        });
        (this.$refs.currencyForm as any).resetFields();
        this.$emit('save-success');
        this.$emit('input', false);
      }
    })
  }

  cancel() {
    (this.$refs.currencyForm as any).resetFields();
    this.$emit('input', false);
  }

  visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value);
    } else {
      if (this.toEdit) {
        this.currency = Util.extend(true, {}, this.$store.state.currency.editCurrency);
      }
      else {
        this.currency = new Currency();
      }
    }
  }

  countryRule = {
    name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
    isoCode: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('ISOCode')), trigger: 'blur'}]
  }
}
